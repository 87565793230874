<template>
    <div class="no-results">
        <div class="d-flex flex-column align-items-center mw245">
            <i class="icon-empty-state text-blue pb-24 fs46"></i>
            <span class="fs14 fw500 pb-8 text-blue">
                Data not available!
            </span>
        </div>
    </div>
</template>
<script>
export default {
    name: "ResultNotFound",
};
</script>
<style>
.no-results {
    position: fixed;
    top: 50%;
    right: calc(50% - 96px);
    z-index: -1;
}
</style>
