<template>
    <div class="xray">
        <b-form @submit.prevent="onSubmit">
            <div class="row">
                <div class="col-3 text-center">
                </div>
                <div class="col-6 drop-file">
                    <div>
                        <b-form-file class="form-input" accept=".zip" v-model="fileData" :state="Boolean(fileData)" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." :disabled="loading"></b-form-file>
                        <div class="mt-2" v-if="fileData">Selected File: {{ fileData ? fileData.name : '' }}</div>
                    </div>
                    <div>
                        <button type="submit" class="upload btn btn-primary" :disabled="loading">Upload File</button>
                    </div>
                </div>
                <div class="col-3 text-center">
                </div>
            </div>
        </b-form>
        <b-container fluid class="table-container">
            <b-table sticky-header striped hover id="xray-table" class="xray-table" :busy.sync="loading" :items="items" :fields="fields"></b-table>
        </b-container>
        <div class="pagination-bar">
            <div class="container-fluid mp">
                <div class="row mp">
                    <div class="col-4 mp">
                        <div class="d-inline-flex wsnw align-items-center">
                            <div class="d-block w50px">
                                <multiselect class="pagination-items__pp" v-model="perPage" :searchable="false" :show-labels="false" :allow-empty="false" :options="pageOptions" @input="setPageNumber"></multiselect>
                            </div>
                            <span class="pl-8 fs12 lh24 text-gray-600 pagination-items"></span>
                        </div>
                    </div>
                    <div class="col-8 text-right mp">
                        <div class="d-inline-flex ml-auto wsnw align-items-center">
                            <div class="fs12 lh24 text-gray-600 pr-14 per-page">
                                Page: {{ currentPage }}
                            </div>
                            <b-button-group>
                                <b-button class="common-btn" @click="prevPage" :disabled="prevDisabled" variant="paginate left">
                                    <i class="icon-back fs12"></i>
                                    <b-icon-arrow-left-square-fill></b-icon-arrow-left-square-fill>
                                </b-button>
                                <b-button class="common-btn" @click="nextPage" :disabled="nextDisabled" variant="paginate right">
                                    <i class="icon-arrow fs12"></i>
                                    <b-icon-arrow-right-square-fill></b-icon-arrow-right-square-fill>
                                </b-button>
                            </b-button-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ResultNotFound v-if="items.length == 0 && !loading" />
        <Loader v-if="loading" :message.sync="message" />
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import ResultNotFound from "@/components/ResultNotFound.vue";
import Loader from "@/components/Loader.vue";

export default {
    name: 'XRayImages',
    title: 'XRayImages',
    data() {
        return {
            loading: false,
            message: null,
            fileData: null,
            pageOptions: [10, 25, 100],
            totalRows: 1,
            currentPage: 1,
            perPage: 25,
            totalPages: 1,
            prevDisabled: true,
            nextDisabled: true,
            startPage: 1,
            pagination: {
                hasNext: true,
                hasPrev: true,
            },
            items: [],
            fields: [
                { key: "sn", label: "S.N.", thClass: "text-left", tdClass: "text-left paddingfirst" },
                { key: "file_name", label: "File Name", thClass: "text-left", tdClass: "text-left paddingfirst" },
                { key: "zip_count", label: "Zip Count", thClass: "text-left", tdClass: "text-left correct" },
                { key: "uploaded_count", label: "Success Count", thClass: "text-left", tdClass: "text-left correct-pad" },
                { key: "failed_count", label: "Failed Count", thClass: "text-left", tdClass: "text-left correct-pad" },
                { key: "created", label: "Uploaded On", thClass: "text-left", tdClass: "text-left correct-pad" },
            ],
        }
    },
    components: {
        Multiselect,
        ResultNotFound,
        Loader
    },
    mounted() {
        this.getXrayImages();
    },
    methods: {
        async onSubmit() {
            this.loading = true;
            this.message = "Uploading file, Please wait...";
            try {
                const formData = new FormData();
                formData.append('xray', this.fileData);
                const response = await this.axios.post(`/xray-images`, formData);
                if (response?.data?.success) {
                    this.fileData = null;
                    this.getXrayImages();
                    this.popToast('Success!', response?.data?.message || 'X-Ray uploaded successfully.', 'danger');
                } else {
                    this.popToast('Failed!', response?.data?.message || 'Unable to upload X-Ray.', 'danger');
                }
            } catch (error) {
                this.popToast('failed', 'Failed', error?.message || error?.response?.data?.message || "Unable to process your request.");
            }
            this.loading = false;
            this.message = null;
        },
        async getXrayImages() {
            this.loading = true;
            try {
                const response = await this.axios.get(`/xray-images?page=${this.currentPage}&offset=${this.perPage}`);
                if (response?.data?.success) {
                    this.items = response.data.data;
                    this.pagination.hasNext = response?.data?.hasNext;
                    this.pagination.hasPrev = response?.data?.hasPrev;
                    this.setPagination();
                } else {
                    this.popToast('failed', 'Failed', response?.data?.message || "Unable to process your request.");
                }
            }
            catch (error) {
                this.popToast('failed', 'Failed', error?.message || error?.response?.data?.message || "Unable to process your request.");
            }
            this.loading = false;
            this.message = null;
        },

        setPagination() {
            this.nextDisabled = this.pagination.hasNext == true ? false : true;
            this.prevDisabled = this.pagination.hasPrev == true ? false : true;
        },

        setPageNumber() {
            this.currentPage = 1;
            this.getXrayImages();
            this.totalPages = Math.ceil(this.totalRows / this.perPage);
            this.pagesArr = [];
            for (var i = 1; i <= this.totalPages; i++) {
                this.pagesArr.push(i);
            }
            this.currentPage = 1;
            this.prevDisabled = true;
            if (this.totalPages == 1) {
                this.nextDisabled = true;
            }
            else if (this.totalPages > 1) {
                this.nextDisabled = false;
            }
        },

        updatePageNumber() {
            this.totalPages = Math.ceil(this.totalRows / this.perPage);
            this.pagesArr = [];
            for (var i = 1; i <= this.totalPages; i++) {
                this.pagesArr.push(i);
            }
        },

        nextPage() {
            this.currentPage++;
            this.getXrayImages();
        },

        prevPage() {
            this.currentPage--;
            this.getXrayImages();
        },

    },
    computed: {
        rows() {
            return this.items.length;
        }
    },
    watch: {
        currentPage: function () {
            this.prevDisabled = false;
            this.nextDisabled = false;
            if (this.currentPage == 1) {
                this.prevDisabled = true;
            }
            if (this.currentPage == this.totalPages) {
                this.nextDisabled = true;
            }
        },
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.xray {
    margin: 0px 0px 0px 0px;
}

.table-container {
    height: calc(100vh - 225px);
    overflow: visible;
    overflow-y: scroll;
}

.xray-table {
    height: 100%;
}

.b-table-sticky-header {
    overflow-y: auto;
    max-height: 100%;
}

.drop-file {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
}

.upload {
    margin: 0px 0px 0px 10px;
    padding: 0.3rem 0.75rem;
}

.pagination-bar {
    width: 97%;
    margin: auto;
    position: fixed;
    bottom: 20px;
}

.common-btn {
    font-size: 1.3rem;
    margin: 0px 3px 0px 0px;
    padding: 0px;
    border: 0px;
    border-radius: 0px;
    cursor: pointer;
}

.per-page {
    margin: 0px 10px 0px 0px;
}

.mp {
    margin: 0px !important;
    padding: 0px !important;
}
</style>
