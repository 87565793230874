<template>
    <div class="loader">
        <div class="center">
            <div class="spinner"><b-spinner class="spinner"></b-spinner></div>
            <div class="message">{{ message || "Please wait..." }}</div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Loader",
    props: ['message'],
};
</script>
<style>
.loader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgb(0 0 0 / 40%);
    z-index: 99999999;
    pointer-events: all;
    cursor: wait;
    border: none;
}

.center {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -100px;
    color: #ffffff;
}

.spinner {
    text-align: center;
}

.message {
    text-align: center;
    margin: 15px 0px;
}

.spinner-border {
    width: 4rem;
    height: 4rem;
}
</style>
